<template lang="pug">
.accordion(:id="`accordion-list-${componentName}`")
    .accordion-header(@click="openAccordion" :id="`accordion-list-header-${componentName}`")
      .accordion-header-title
        span(:class="[isAccordionOpen ? 'active' : '']" :id="`accordion-list-header-title-${componentName}`") {{ title }}
      .accordion-header-right
        Icon.icon(name="icon-arrow-down" :class="[isAccordionOpen ? 'icon-reverse' : '']")
    transition(
      name="accordionData"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    )
      .accordion-body(v-if="isAccordionOpen" :id="`accordion-list-body-desc-${componentName}`")
        p.p-left-20(v-show="desc") {{ desc }}
        slot(name="accordion-body")
</template>

<script>
export default {
  inject: ['AccordionListData'],

  data () {
    return {
      activeIndex: null
    }
  },

  props: {
    title: {
      type: String,
      default: 'Empty Title'
    },
    desc: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    componentName: {
      type: String,
      default: 'componentName'
    }
  },

  computed: {
    isAccordionOpen () {
      return this.activeIndex === this.AccordionListData.active
    }
  },

  methods: {
    openAccordion () {
      this.isAccordionOpen ? this.AccordionListData.active = null : this.AccordionListData.active = this.activeIndex
    },

    start (el) {
      el.style.height = el.scrollHeight + 'px'
    },

    end (el) {
      el.style.height = ''
    }
  },

  created () {
    this.activeIndex = this.AccordionListData.count++
    if (this.isActive) this.openAccordion()
  }

}
</script>

<style lang="scss">

  @import '~@/stylesheet/config/variables.scss';

  $transition-change: all .3s ease;

  .accordion {
    &-header {
      width: 100%;
      padding: 8px 20px 12px 20px;
      background-color: $color-light-gray;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &-title {
        span {
          transition: $transition-change;
        }
        .active {
          color: $color-primary;
          border-bottom: 1px solid $color-primary;
        }
      }

      &-right {
        .icon {
          transition: $transition-change;
          &.icon-reverse {
            transform: rotate(180deg);
            color: $color-primary;
          }
        }

      }

    }
    &-body {
      margin-top: 10px;
    }
  }

  .accordionData-enter-active,
  .accordionData-leave-active {
    transition: $transition-change;
    overflow: hidden;
  }

  .accordionData-enter,
  .accordionData-leave-to {
    height: 0 !important;
    opacity: 0;
    margin: 0;
  }
</style>
