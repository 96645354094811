var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "accordion",
      attrs: { id: `accordion-list-${_vm.componentName}` },
    },
    [
      _c(
        "div",
        {
          staticClass: "accordion-header",
          attrs: { id: `accordion-list-header-${_vm.componentName}` },
          on: { click: _vm.openAccordion },
        },
        [
          _c("div", { staticClass: "accordion-header-title" }, [
            _c(
              "span",
              {
                class: [_vm.isAccordionOpen ? "active" : ""],
                attrs: {
                  id: `accordion-list-header-title-${_vm.componentName}`,
                },
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
          ]),
          _c(
            "div",
            { staticClass: "accordion-header-right" },
            [
              _c("Icon", {
                staticClass: "icon",
                class: [_vm.isAccordionOpen ? "icon-reverse" : ""],
                attrs: { name: "icon-arrow-down" },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "transition",
        {
          attrs: { name: "accordionData" },
          on: {
            enter: _vm.start,
            "after-enter": _vm.end,
            "before-leave": _vm.start,
            "after-leave": _vm.end,
          },
        },
        [
          _vm.isAccordionOpen
            ? _c(
                "div",
                {
                  staticClass: "accordion-body",
                  attrs: {
                    id: `accordion-list-body-desc-${_vm.componentName}`,
                  },
                },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.desc,
                          expression: "desc",
                        },
                      ],
                      staticClass: "p-left-20",
                    },
                    [_vm._v(_vm._s(_vm.desc))]
                  ),
                  _vm._t("accordion-body"),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }